@import "../../Common/CommonScss/mixins.scss";

.ctaBtn {
  %theme-blue {
    background: $theme-blue !important;
    // border: 1px solid $theme-blue;
    color: $white;
    @include border-radius(8px);
    font-weight: 500;
    text-transform: capitalize;

    &:hover {
      opacity: 0.7;
    }
  }

  // %secondary {
  //   background: $secondary;
  //   color: $white;
  //   @include border-radius(6px);
  //   text-transform: capitalize;

  //   &:hover {
  //     background: lighten($secondary, 8%);
  //   }
  // }

  .blueXXlBtn {
    @extend %theme-blue;
    @include fontSize(18px);
    font-weight: 400;
    padding: 12px 66px;
    line-height: 24px;
  }

  .blueXlBtn {
    @extend %theme-blue;
    @include fontSize(18px);
    font-weight: 400;
    padding: 12px 28px;
    line-height: 24px;
  }

  .blueLgBtn {
    @extend %theme-blue;
    @include fontSize(16px);
    font-weight: 500;
    padding: 12px 24px;
    line-height: 21px;
  }

  .blueMdBtn {
    @extend %theme-blue;
    @include fontSize(14px);
    padding: 10px 16px;
    line-height: 14px;

    &.border {
      border: 1px solid $white;
      @include border-radius(8px);
    }
  }

  .blueSmBtn {
    @extend %theme-blue;
    @include fontSize(12px);
    padding: 4px 14px;
  }

  .blueXsBtn {
    @extend %theme-blue;
    @include fontSize(10px);
    padding: 2px 14px;
  }
}
