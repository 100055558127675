@import "./../../../Common/CommonScss/mixins.scss";

.requestDemo {
    .img-sec {
        padding: 10px;

        img {
            width: 100%;
            max-width: 361px;
            max-height: 232px;
            margin: auto;
        }
    }

    .field-sec {
        border-radius: 20px;
        background: #f3f2fc;
        padding: 20px;
    }
}