@import '../../../Common/CommonScss/mixins.scss';

.footer {
    background: $theme-blue;
    padding: 54px 25px;

    .row {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
    }

    .footer_boy {
        margin-bottom: 28px;
    }

    h6 {
        font-family: 'poppins', sans-serif;
        font-weight: 600;
        @include fontSize(20px);
        line-height: 25px;
        color: $white;
        margin-bottom: 24px;
    }

    .product_list {
        li {
            margin-bottom: 16px;
            @include fontSize(14px);
            font-family: 'poppins', sans-serif;
            font-weight: 400;

            a {
                color: rgba(255, 255, 255, 0.6);

                &:hover {
                    color: $white;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    hr {
        border: 1px solid rgba(255, 255, 255, 0.6) !important;
    }

    .address_detail {
        a {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            @include fontSize(14px);
            line-height: 21px;
            color: $white;
        }

        span {
            font-family: 'Poppins', sans-serif;
            @include fontSize(14px);
            font-weight: 400;
            line-height: 21px;
            color: $white;
        }

        p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            @include fontSize(14px);
            line-height: 21px;
            color: $white;
            margin-top: 11px;
        }
    }

    .social_sites {
        ul {
            display: flex;
            justify-content: end;

            @media(max-width:992px) {
                justify-content: start;
            }

            li {
                margin-right: 19px;
                margin-bottom: 8px;

                &:last-child {
                    margin-right: 0px;
                }

                a {
                    outline: none;

                    &:hover,
                    &:focus {
                        opacity: 0.7;
                        outline: none;
                    }
                    img{ 
                        width: 22px;
                        height: 28px;
                    }

                }
            }
        }

        p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            @include fontSize(14px);
            line-height: 21px;
            color: $white;
            text-align: end;
            margin-bottom: 0;

            @media(max-width:992px) {
                text-align: start;
            }
        }
    }
}