@import "../../../../Common/CommonScss/mixins.scss";

.insurance_partner_wrapper {
    padding-top: 92px;

    .row {
        max-width: 1224px;
        width: 100%;
        margin: 0 auto;
    }

    .insurer_partner {
        .insurer_box {
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            li {
                list-style-type: none;
                margin: 12px;
                @include border-radius(12px);
                background: $white;
                width: 176px;
                height: 160px;
                box-shadow: 26px 26px 89px 0px rgba(0, 0, 0, 0.10);
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 90px;
                }
            }
        }
    }

    .potential_client {
        background: $theme-blue;
        @include border-radius(30px);
        padding: 43px 50px;
        position: relative;

        &:before {
            content: '';
            background: url("../../../../../public/images/lines.svg") no-repeat;
            @include position(absolute, $left: 0px, $top: 0);
            height: 100%;
            width: 100%;
        }

        h5 {
            font-family: 'Poppins';
            font-weight: 600;
            @include fontSize(18px);
            line-height: 29px;
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: 6px;
        }

        p {
            font-family: 'Poppins';
            font-weight: 700;
            @include fontSize(24px);
            line-height: 35px;
            color: #E0DEF7;
            margin-bottom: 0px;
        }

        ul {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;

            li {
                font-family: 'poppins';
                font-weight: 400;
                @include fontSize(16px);
                line-height: 32px;
                text-align: center;
                color: #F2F2F2;
                position: relative;
                min-width: 100px;
                padding: 0 35px;
                border-right: 1px solid $white;

                &:last-child {
                    border-right: none;
                }

                @media(max-width:992px) {
                    line-height: 22px;
                }

                @media(max-width:1122px) {
                    border-right: none;
                }
            }

            span {
                display: flex;
                padding-top: 6px;
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                @include fontSize(42px);
                line-height: 50px;
                justify-content: center;

                .inner_span {
                    position: relative;
                    top: 3px;
                    left: 5px;
                }
            }
        }

    }
}