@import "../../../Common/CommonScss/mixins.scss";

.homepage_wrapper {
    padding-top: 92px;

    .row {
        max-width: 1224px;
        width: 100%;
        margin: 0 auto;
    }

    .header {
        min-height: 530px;
        margin-bottom: 24px;
        position: relative;
        background: url("./../../../../public/images/header_bg_insurevent.svg") no-repeat center/1200px;

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            content: '';
            background: linear-gradient(0, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
            height: 100px;
            width: 100%;
            // z-index: -1;
        }

        &:after {
            @include position(absolute, $bottom: 0, $left: 0);
            content: '';
            background: linear-gradient(0, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
            height: 100px;
            width: 100%;
            // transform: rotateX(90deg);
        }

        @media (max-width:1200px) {
            min-height: 430px;
        }

        .header_outer_content {
            animation: fadeIn 4s forwards;
            opacity: 0;

            .active {
                animation: fadeIn 1s forwards;
                opacity: 1;
            }

            .header_content {
                width: 55%;
                margin: auto;

                h2 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    @include fontSize(46px);
                    line-height: 64px;
                    color: #444444;
                    margin-bottom: 14px;

                    @media (max-width:1200px) {
                        @include fontSize(36px);
                        line-height: 60px;
                    }

                    span {
                        font-weight: 700 !important;
                        color: $theme-blue;
                    }
                }

                p {
                    font-family: 'poppins', sans-serif;
                    font-weight: 400;
                    @include fontSize(14px);
                    line-height: 24px;
                    color: rgba(0, 0, 0, 0.77);
                    margin-bottom: 14px;
                    max-width: 80%;
                }
            }

            .img_section {
                display: inline-block;
                text-align: end;
                width: 45%;
                margin: auto;
                // animation: fadeOut 1s forwards;
                // opacity: 1;

                // .active {
                //     animation: fadeOut 1s forwards;
                //     opacity: 0;
                // }
                .headerimg {
                    img {
                        max-width: 556px;
                        width: 100%;
                    }
                }
            }
        }

        .react-multi-carousel-item--active {
            animation: fadeIn 1s forwards;
            opacity: 1;
        }

        .react-multiple-carousel__arrow--left {
            display: none;
        }

        .react-multiple-carousel__arrow--right {
            display: none;
        }

        .react-multi-carousel-dot-list {
            .react-multi-carousel-dot {
                display: none;
            }
        }

        .blueBtn {
            &.arrow_icon {
                padding-right: 68px;
                position: relative;

                &:before {
                    content: '';
                    @include position(absolute, $right: 18px, $top: calc(50% - 10px));
                    background: url("../../../../public/images/explore_right_arrow.svg") no-repeat;
                    height: 20px;
                    width: 20px;
                }
            }
        }

        @keyframes fadeIn {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }

        /* Define the animation for fade-out effect */
        @keyframes fadeOut {
            from {
                opacity: 1;
            }

            to {
                opacity: 0;
            }
        }
    }

    .partners_section {
        .innerSection {
            @include border-radius(25px);

            .carousel-part {
                width: 100%;

                .partner_box {
                    padding: 22px 13px;
                    @include border-radius(20px);
                    border: 1px solid rgba(0, 0, 0, 0.08);
                    background: $white;
                    // box-shadow: 26px 26px 89px 0px rgba(0, 0, 0, 0.10);
                    width: 135px;
                    height: 112px;
                    margin: 40px 0px 30px;

                    img {
                        height: 60px;
                        margin: auto;
                    }
                }

                .react-multi-carousel-track {
                    margin-bottom: 30px;
                }

                .react-multi-carousel-dot-list {
                    display: none;
                }
            }
        }
    }

    .insurance_broker {
        .innerSection {
            display: flex;
            @include border-radius(20px);
            border: 1px solid #F8F8FF;
            background: #F8F8FF;
            padding: 20px;
            position: relative;
            overflow: hidden;

            &:hover {
                background: $theme-blue;

                .text_section {
                    h6 {
                        color: $white;
                    }

                    p {
                        color: $white;
                    }
                }


            }

            &:before {
                content: '';
                background: url("../../../../public/images/ellipse1.svg") no-repeat;
                height: 122px;
                width: 198px;
                @include position(absolute, $right: -47px, $top: 0);
            }

            &:after {
                content: '';
                background: url("../../../../public/images/ellipse2.svg") no-repeat;
                height: 122px;
                width: 198px;
                @include position(absolute, $left: 0, $bottom: 0);
            }

            .img_section {
                margin-right: 24px;

                .image {
                    height: 180px;
                    min-width: 160px;
                }
            }

            .text_section {
                h6 {
                    font-weight: 700;
                    @include fontSize(20px);
                    line-height: 35px;
                    margin-bottom: 10px;
                    color: #444444;
                }

                p {
                    font-weight: 400;
                    @include fontSize(14px);
                    line-height: 24px;
                    margin-bottom: 0px;
                    color: #444444;
                    text-align: justify;
                }
            }

        }
    }

    .insurance_offering {
        .tab_list {
            @include border-radius(10px);
            background: #F3F2FC;
            padding: 10px;
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            height: 70px;

            li {
                list-style-type: none;
                width: 150px;
                margin: 0 10px;
                text-align: center;
                cursor: pointer;

                .MuiButtonBase-root {
                    font-weight: 400;
                    @include fontSize(14px);
                    line-height: 18px;
                    color: $black;
                    padding: 0;
                    text-transform: capitalize;
                    font-family: "poppins";

                    @media(max-width:1050px) {
                        @include fontSize(10px);
                    }

                    &.active {
                        @include border-radius(10px);
                        padding: 16px 10px;
                        background: $theme-blue;
                        color: $white !important;

                        .MuiButtonBase-root {
                            color: $white;
                        }
                    }
                }
            }
        }

        .content_box {
            background: #EEEEFD;
            margin-bottom: 40px;
            padding: 20px;
            @include border-radius(8px);
            transition: background-color 0.3s;
            position: relative;
            z-index: 99;
            cursor: pointer;
            overflow: hidden;

            // &:before {
            //     content: '';
            //     background: #F8F8FF;
            //     height: 100%;
            //     width: 100px;
            //     @include position(absolute, $right: 0, $top: 0px);
            //     z-index: -9;
            // }

            // &:after {
            //     content: '';
            //     width: 100px;
            //     height: 100%;
            //     border-radius: 50px/100px;
            //     @include position(absolute, $right: 65px, $top: 0px);
            //     background: #f3f2fc;
            //     z-index: -9;
            // }
            &:after {
                @include position(absolute, $top: 50%, $left: 0);
                transform: translateY(-50%);
                content: '';
                background: #F8F8FF;
                height: 400%;
                width: 92%;
                z-index: -2;
                border-radius: 0 70% 70% 0;
            }

            &:hover {
                .content_desc {
                    // display: block;
                    max-height: 500px;
                    transition: max-height 0.3s ease;
                    
                }

                background-color: #7E74DF;
                color: $white;

                &:before {
                    background-color: #7E74DF;
                }

                &:after {
                    background-color: $theme-blue;
                }
            }

            .content_title {
                margin-bottom: 13px;
                display: flex;
                align-items: center;

                img {
                    height: 72px;
                    margin-right: 13px;
                }

                h6 {
                    font-weight: 600;
                    @include fontSize(18px);
                    line-height: 30px;
                    margin-bottom: 0;
                    cursor: pointer;
                    position: relative;
                    z-index: 99;
                }
            }

            .content_desc {
                // display: none;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.3s ease-in-out;

                p {
                    font-weight: 400;
                    @include fontSize(14px);
                    line-height: 21px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .product_basket {
        background: #F1EFFF;
        padding: 48px 0;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            li {
                list-style-type: none;
                box-shadow: 0px 0px 20px 0px rgba(153, 148, 199, 0.30) inset;
                @include border-radius(20px);
                background: $white;
                height: 160px;
                width: 210px;
                margin: 74px 12px 12px;
                padding: 0 10px 30px;
                box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10);

                .image_Section {
                    height: 90px;
                    width: 90px;
                    @include border-radius(12px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    position: relative;
                    top: -35px;

                    img {
                        height: 60px;
                        display: inline-block;
                        text-align: center;
                    }
                }

                $box-colors: (
                    "health",
                    #FFB197),
                    ("term", #F2D3FF),
                    ("car", #AABCFF),
                    ("bike", #FCECB6),
                    ("travel", #E0C7FB),
                    ("investment", #EDD5B4),
                    ("shopkeeper", #C2E7F3),
                    ("home", #B5CEFF),
                    ("commercial", #D6D2FF
                );
            $k: 0;

            @each $color in $box-colors {
                $k: $k +1;

                .#{nth($color,1)} {

                    background-color: nth($color, 2) !important;
                }
            }

            .text_section {
                h6 {
                    @include fontSize(16px);
                    line-height: 24px;
                    font-weight: 500;
                    text-align: center;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.upcomimg_product {
    .upcoming_innersection {
        padding-top: 140px;
        position: relative;
        margin-bottom: 90px;

        .img_section {
            display: flex;
            justify-content: center;

            img {
                height: 265px !important;
            }
        }

        &:before {
            content: '';
            background: url("../../../../public/images/upcoming_bg.svg") no-repeat center center/100%;
            @include position(absolute, $left: 0, $top: 140px);
            height: 68%;
            width: 100%;
            z-index: -99;
        }

        @media(max-width:992px) {
            &:before {
                display: none;
            }
        }

        .upcoming_heading {
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
            @include fontSize(24px);
            line-height: 35px;
            color: #252243;
            margin-bottom: 12px;
        }

        .upcoming_content {
            font-family: 'poppins', sans-serif;
            font-weight: 400;
            @include fontSize(16px);
            line-height: 24px;
            color: rgba(0, 0, 0, 0.56);
            margin-bottom: 0;
        }

    }

    .img_filter {
        filter: grayscale(0.6);
    }

    .bg_filter {
        // filter: grayscale(1);
        background-color: #E0E0E0 !important;
    }

    .MuiLink-root {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        @include fontSize(18px);
        line-height: 23px;
        color: #BDBDBD;
        background: transparent;
        position: relative;
        top: 70px;
        height: 70px;
        text-transform: capitalize;

        &.active {
            color: $black !important;
            font-weight: 700;

            &:after {
                filter: grayscale(0) !important;
                background: $theme-blue !important;
            }

            &:before {
                filter: grayscale(0) !important;
            }
        }

        @media(max-width:850px) {
            padding: 8px 10px;
        }

        &:after {
            content: "";
            @include position(absolute, $left: calc(50% - 56px), $top: -70px);
            width: 112px;
            height: 70px;
            background: $theme-blue;
            @include border-radius(0px 0px 80px 80px);
            filter: grayscale(1);
        }

    }
}

.difference_section {
    .difference_innerSection {
        .difference-carousel-part {
            width: 100%;

            .difference_box {
                // min-width: 700px;
                margin: 40px 0px 30px;
                display: flex;
                overflow: hidden;

                .image_section {
                    margin-right: 12px;
                    background: #F6F5FF;
                    @include border-radius(20px);
                    padding: 44px 18px;

                    img {
                        height: 200px;
                        margin: auto;
                        min-width: 246px;
                    }
                }


                .text_section {
                    margin: 0 12px;

                    h6 {
                        font-weight: 700;
                        @include fontSize(20px);
                        line-height: 35px;
                        margin-bottom: 10px;
                        color: $black;
                    }

                    p {
                        font-weight: 400;
                        @include fontSize(14px);
                        line-height: 24px;
                        margin-bottom: 0;
                        color: #707070;
                    }
                }
            }

            .react-multi-carousel-track {
                margin-bottom: 30px;
            }

            .react-multi-carousel-dot-list {

                .react-multi-carousel-dot {
                    button {
                        height: 10px;
                        width: 10px;
                        margin: 0px 3px;
                        border: none;
                        @include border-radius(10px);
                        background: #D9D9D9;
                    }

                    &.react-multi-carousel-dot--active {
                        button {
                            background: $theme-blue;
                            height: 15px;
                            width: 15px;
                            position: relative;
                            top: 3px
                        }
                    }
                }
            }
        }
    }
}

.services_section {
    .services_innerSection {
        @include border-radius(25px);

        .services-carousel-part {
            width: 100%;

            .services_box {
                padding: 0 24px 24px;
                @include border-radius(30px);
                background: #F3F2FC;
                width: 384px;
                min-height: 372px;
                margin: 80px 0px 30px;

                img {
                    height: 205px;
                    margin: auto;
                    position: relative;
                    top: -60px;
                }

                h6 {
                    font-weight: 700;
                    @include fontSize(22px);
                    line-height: 30px;
                    margin-bottom: 12px;
                    text-align: center;
                    color: $black;
                }

                p {
                    font-weight: 400;
                    @include fontSize(14px);
                    line-height: 24px;
                    margin-bottom: 0;
                    color: #707070;
                    text-align: center;
                }

                &:hover {
                    background: $theme-blue;

                    h6 {
                        color: $white;
                    }

                    p {
                        color: $white;
                        opacity: 0.6;
                    }
                }

                @media(max-width:800px) {
                    width: 350px;
                }
            }


            .react-multi-carousel-track {
                margin-bottom: 30px;
            }

            .react-multi-carousel-dot-list {

                .react-multi-carousel-dot {
                    button {
                        border: none;
                        background: #D9D9D9;
                        height: 2px;
                        width: 35px;
                        margin: 0px 2px;
                        padding: 1px 0;
                    }

                    &.react-multi-carousel-dot--active {
                        button {
                            background: $theme-blue;
                        }
                    }
                }
            }

        }

    }

}

.counter_section {
    background: #F3F2FC;

    .counter_section_inner {
        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                width: 50%;
                position: relative;
                margin-bottom: 24px;

                &:nth-child(even) {
                    padding-left: 60px;
                    border-left: 2px solid rgba(0, 0, 0, 0.1);
                }

                .counterdiv {
                    padding: 10px 0px;

                    h3 {
                        @include fontSize(42px);
                        line-height: 50px;
                        font-weight: 700;

                        @media(max-width:992px) {
                            @include fontSize(28px);
                            line-height: 40px;
                        }
                    }

                    p {
                        @include fontSize(16px);
                        line-height: 32px;
                        font-weight: 400;
                        color: #222222;
                    }
                }
            }
        }
    }
}

.feedbacksection {
    .feedback_innerSection {
        @include border-radius(25px);

        .feedback-carousel-part {
            width: 100%;

            .feedback_box {
                padding: 20px;
                @include border-radius(20px);
                border: 1px solid #6458D8;
                width: 384px;
                min-height: 372px;
                margin: 80px 0px 30px;

                @media (max-width:1200px) {
                    width: 320px;
                }

                @media (max-width:991px) {
                    width: 364px;
                }


                .quotes_img {
                    background: url("../../../../public/images/quotes.svg") no-repeat;
                    height: 40px;
                    width: 40px;
                    display: inline-block;
                    @include position(absolute, $left: calc(60% - 20px), $bottom: 0);
                }

                img {
                    height: 100px;
                    margin: auto;
                    position: relative;
                }

                h6 {
                    font-weight: 600;
                    @include fontSize(16px);
                    line-height: 29px;
                    margin: 30px 0px 0px;
                    text-align: center;
                    color: #707070;

                    span {
                        font-weight: 400;
                        @include fontSize(14px);
                        line-height: 32px;
                        color: #707070;
                        display: block;
                    }
                }

                p {
                    font-weight: 400;
                    @include fontSize(14px);
                    line-height: 32px;
                    margin-bottom: 40px;
                    color: #707070;
                    text-align: center;
                }

                .MuiStack-root {
                    display: block;
                    text-align: center;
                }
            }

            .react-multi-carousel-track {
                margin-bottom: 30px;
            }

            .react-multi-carousel-dot-list {
                display: none;

                // .react-multi-carousel-dot {
                //     button {
                //         height: 10px;
                //         width: 10px;
                //         margin: 0px 3px;
                //         border: none;
                //         border-radius: 10px;
                //         background: #D9D9D9;
                //     }

                //     &.react-multi-carousel-dot--active {
                //         button {
                //             background: $theme-blue;
                //             height: 15px;
                //             width: 15px;
                //         }
                //     }
                // }
            }

        }
    }
}

.bluebg_box {
    background: $theme-blue;
    @include border-radius(20px);
    padding: 20px;
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;

    h5 {
        font-weight: 600;
        @include fontSize(16px);
        line-height: 24px;
        color: $white;
    }

    svg {
        color: $white;
        height: 24px;
        cursor: pointer;
        animation: moveLeftRight 1s infinite ease-in-out;
    }


    @keyframes moveLeftRight {

        0%,
        100% {
            transform: translateX(0);
        }

        50% {
            transform: translateX(10px);
            /* adjust this value based on how much you want the arrow to move */
        }
    }
}
}