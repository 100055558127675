@import "../../../Common/CommonScss/mixins.scss";

.request_wrapper {
    .request_demo {
        margin-bottom: 100px;
        border: 1px solid rgba(0, 0, 0, 0.08);
        @include border-radius(20px);
        padding: 40px;

        @media(max-width:1200px) {
            text-align: center;
        }

        h6 {
            font-weight: 700;
            @include fontSize(34px);
            line-height: 51px;
            color: #444444;
            margin-bottom: 6px;

            span {
                color: $theme-blue;
            }
        }

        p {
            font-weight: 400;
            @include fontSize(14px);
            line-height: 24px;
            color: rgba(0, 0, 0, 0.56);
            margin-bottom: 24px;
        }

        img {
            width: 500px;

            @media(max-width:992px) {
                width: 350px;
            }
        }

        .crm_formSection {
            padding: 40px;
            background: #F3F2FC;
            @include border-radius(30px);

            .blueBtn {
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                @include fontSize(14px);
                line-height: 21px;
                color: $white;
                background: $theme-blue;
                padding: 14px 24px;
                @include border-radius(8px);
                display: inline-block;
                text-transform: capitalize;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}