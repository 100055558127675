@import "../../../Common/CommonScss/mixins.scss";

.productPage_wrapper {
    padding-top: 90px;

    .row {
        max-width: 1224px;
        width: 100%;
        margin: 0 auto;
    }

    .productHeader_section {
        background: $theme-blue;

        h1 {
            font-weight: 400;
            @include fontSize(42px);
            color: $white;
            margin-bottom: 0px;

            span {
                font-weight: 700;
            }
        }

        img {
            display: inline-block;
            height: 414px;
        }
    }

    .productInfo_wrapper {
        .productInfo_section {
            background: #F3F2FC;
            padding: 40px;
            @include border-radius(60px);

            h6 {
                font-weight: 700;
                @include fontSize(34px);
                line-height: 51px;
                color: $black;
                margin-bottom: 6px;

                span {
                    color: $theme-blue;
                }
            }

            p {
                font-weight: 400;
                @include fontSize(14px);
                line-height: 24px;
                color: #6B6A6F;
                margin-bottom: 0px;
            }

            img {
                width: 530px;
            }
        }

    }

    .what_crm {
        margin-bottom: 100px;

        h6 {
            font-weight: 400;
            @include fontSize(42px);
            line-height: 63px;
            text-transform: capitalize;
            color: #444444;
            margin-bottom: 0;

            span {
                font-weight: 700;
                color: $theme-blue;
            }
        }

        p {
            font-weight: 400;
            @include fontSize(14px);
            line-height: 24px;
            text-align: justify;
            color: #4f4f4f;
            margin-bottom: 0;
        }
    }

    .erpmodule_description {
        .module_box {
            background: $white;
            box-shadow: 26px 26px 89px rgba(0, 0, 0, 0.1);
            @include border-radius(20px);
            margin-bottom: 75px;
            min-height: 500px;

            @media(max-width:1200px) {
                min-height: 520px;
            }

            &.space_top {
                position: relative;
                top: 80px;

                @media(max-width:1200px) {
                    top: 0;
                }
            }

            .inner_box {
                background: $white;
                @include border-radius(20px);
                height: 276px;

                img {
                    height: 310px;
                    position: relative;
                    top: -65px;
                    display: inline-block;
                }

            }

            .content_box {
                padding: 0 30px 30px;

                h5 {
                    font-weight: 600;
                    @include fontSize(18px);
                    line-height: 29px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.56);
                    margin: 0px 0 10px;
                    padding-top: 30px;
                }

                p {
                    font-weight: 400;
                    @include fontSize(14px);
                    line-height: 24px;
                    text-align: center;
                    color: #252243;
                    opacity: 0.5;
                    margin-bottom: 0;
                }
            }



            &:hover {
                .inner_box {
                    background: #F3F2FC;
                    ;
                    @include border-radius(20px);
                    height: 276px;

                    img {
                        height: 310px;
                    }

                }

                .content_box {
                    h5 {
                        color: $theme-blue;
                    }

                    p {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .popsp_module_description {
        .posp_module_description_list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
                width: 282px;
                height: 195px;
                position: relative;

                .module_content {
                    position: relative;
                    left: 30px;
                    top: 22px;

                    img {
                        width: 60px;
                    }

                    h6 {
                        font-weight: 500;
                        @include fontSize(18px);
                        line-height: 29px;
                        color: $black;
                        margin-bottom: 0;
                        padding: 35px 0 0;
                        text-align: left;
                        max-width: 260px;
                    }
                }

                &:before {
                    content: '';
                    background: $black;
                    height: 99px;
                    width: 1px;
                    @include position(absolute, $left: 0, $top: calc(50% - 49px));
                }

                &:after {
                    content: '';
                    background: $theme-blue;
                    height: 40px;
                    width: 11px;
                    @include border-radius(6px);
                    @include position(absolute, $left: 0, $top: calc(50% - 69px));
                }

                .module_overlay {
                    display: none;

                }

                &:hover {
                    .module_overlay {
                        padding: 20px;
                        background: $theme-blue;
                        @include border-radius(10px);
                        position: absolute;
                        top: 0;
                        z-index: 9;
                        transition: 0.4s;
                        height: 100%;
                        width: 100%;
                        display: block;

                        h5 {
                            font-weight: 600;
                            @include fontSize(18px);
                            line-height: 29px;
                            color: $white;
                            margin-bottom: 6px;
                        }

                        p {
                            font-weight: 600;
                            @include fontSize(14px);
                            line-height: 24px;
                            color: $white;
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }

    .crm_modules_description {
        background: $theme-blue;
        @include border-radius(60px);
        padding: 80px 40px 0;

        h5 {
            font-weight: 700;
            @include fontSize(42px);
            line-height: 63px;
            text-transform: capitalize;
            color: $white;
            margin-bottom: 6px;
        }

        p {
            font-weight: 400;
            @include fontSize(14px);
            line-height: 24px;
            color: $white;
            margin-bottom: 96px;
        }

        img {
            max-height: 442px;

            @media(max-width:992px) {
                height: 350px;
            }
        }

        .crm_module_description_list {
            min-height: 680px;
            max-height: 680px;
            overflow-y: auto;
            scrollbar-width: none;


            ul {
                margin-bottom: 0;
                margin-right: 10px;

                .module_innner_list {
                    background: $white;
                    border: 1px solid rgba(0, 0, 0, 0.08);
                    box-shadow: 26px 26px 95px rgba(0, 0, 0, 0.2);
                    @include border-radius(20px);
                    padding: 20px;
                    margin-bottom: 25px;
                    position: relative;
                    $producticons: module_list1,
                        module_list2,
                        module_list3,
                        module_list4,
                        module_list5;
                    $k: 0;

                    @each $icons in $producticons {
                        $bacRightP: $k * 62;
                        $k: $k +1;

                        .#{$icons} {
                            &:before {
                                content: "";
                                @include position(absolute, $left: 20px, $top: calc(50% - 30px));
                                width: 62px;
                                height: 62px;
                                background: url("../../../../public/images/crm_module_list_img.svg")no-repeat -#{$bacRightP}px 0px/310px;
                            }
                        }
                    }


                    .module_subinnner_list {
                        display: flex;
                        gap: 8px;

                        .description_img {
                            min-width: 82px;
                            height: 82px;
                            position: relative;

                            img {
                                height: 60px;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }

                        .description_content {
                            h6 {
                                font-weight: 600;
                                @include fontSize(20px);
                                color: #444444;
                                margin-bottom: 6px;
                            }

                            ul {
                                margin-bottom: 0;

                                li {
                                    font-weight: 400;
                                    @include fontSize(14px);
                                    line-height: 24px;
                                    color: #4f4f4f;
                                    margin-bottom: 2px;
                                    position: relative;
                                    padding-left: 21px;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    &:before {
                                        content: '';
                                        background: url("../../../../public/images/module_list_arrow.svg") no-repeat;
                                        height: 24px;
                                        width: 16px;
                                        @include position(absolute, $left: 0px, $top: 10px);

                                    }
                                }
                            }

                            .para {
                                font-weight: 400;
                                @include fontSize(14px);
                                line-height: 24px;
                                color: rgba(0, 0, 0, 0.56);
                                margin-bottom: 2px;
                                position: relative;
                                padding-left: 21px;

                                span {
                                    position: relative;
                                    left: -20px;

                                    img {
                                        height: 10px;
                                    }
                                }



                                // &:before {
                                //     content: '';
                                //     background: url("../../../../public/images/module_list_arrow.svg") no-repeat;
                                //     height: 24px;
                                //     width: 16px;
                                //     @include position(absolute, $left: 0px, $top: 10px);

                                // }
                            }
                        }

                        // ul {
                        //     margin-bottom: 0;

                        //     li {
                        //         font-family: 'Poppins', sans-serif;
                        //         font-weight: 400;
                        //         @include fontSize(14px);
                        //         line-height: 24px;
                        //         color: rgba(0, 0, 0, 0.56);
                        //         margin-bottom: 2px;
                        //         position: relative;
                        //         padding-left: 21px;

                        //         &:last-child {
                        //             margin-bottom: 0;
                        //         }

                        //         &:before {
                        //             content: '';
                        //             background: url("../../../../public/images/module_list_arrow.svg") no-repeat;
                        //             height: 24px;
                        //             width: 16px;
                        //             @include position(absolute, $left: 0px, $top: 10px);

                        //         }
                        //     }
                        // }
                    }
                }
            }
        }

        ::-webkit-scrollbar {
            width: 8px;
            border-radius: 10px;
            /* Set the width of the scrollbar */
        }

        ::-webkit-scrollbar-thumb {
            background-color: #7D73DE;
            /* Set the color of the scrollbar thumb */
        }

        ::-webkit-scrollbar-track {
            background-color: #f1f1f1;
            /* Set the color of the scrollbar track */
        }
    }

    .aggregationmodule_description {
        .aggregation_description_list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
                // width: 25%;
                width: 282px;
                height: 238px;
                position: relative;
                text-align: center;
                margin-bottom: 20px;

                .module_content {
                    img {
                        height: 128px;
                    }

                    h6 {
                        font-weight: 500;
                        @include fontSize(18px);
                        line-height: 29px;
                        color: $black;
                        margin: 0;
                        padding: 0;
                        text-align: center;
                        max-width: 260px;
                    }
                }

                .module_overlay {
                    display: none;
                }

                &:hover {
                    .module_overlay {
                        padding: 20px;
                        background: $theme-blue;
                        @include border-radius(10px);
                        position: absolute;
                        top: 0;
                        z-index: 9;
                        transition: 0.4s;
                        height: 100%;
                        width: 100%;
                        display: block;

                        h5 {
                            font-weight: 600;
                            @include fontSize(18px);
                            line-height: 29px;
                            color: $white;
                            margin-bottom: 10px;
                            text-align: center;
                        }

                        p {
                            font-weight: 600;
                            @include fontSize(14px);
                            line-height: 24px;
                            color: $white;
                            text-align: center;
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }

    .si_module_description {
        .si-carousel-part {
            width: 100%;

            .difference_box {
                // min-width: 700px;
                margin: 40px 0px 30px;
                display: flex;
                overflow: hidden;

                .image_section {
                    margin-right: 12px;
                    background: #F6F5FF;
                    @include border-radius(20px);
                    padding: 44px 18px;

                    img {
                        height: 200px;
                        margin: auto;
                        min-width: 246px;
                    }
                }


                .text_section {
                    margin: 0 12px;

                    h6 {
                        font-weight: 700;
                        @include fontSize(20px);
                        line-height: 35px;
                        margin-bottom: 10px;
                        color: $black;
                    }

                    p {
                        font-weight: 400;
                        @include fontSize(14px);
                        line-height: 24px;
                        margin-bottom: 0;
                        color: #707070;
                    }
                }
            }

            .modulebox {
                ul {
                    display: flex;
                    gap: 24px;
                    flex-wrap: wrap;

                    li {
                        width: calc(50% - 12px);
                        @include border-radius(20px);
                        padding: 16px;
                        text-align: left;

                        &:hover {
                            background: $theme-blue;

                            h6,
                            p {
                                color: $white;
                            }
                        }

                        h6 {
                            font-weight: 600;
                            @include fontSize(16px);
                            margin-bottom: 12px;
                            margin-top: 18px;
                            color: $black;
                        }

                        p {
                            font-weight: 400;
                            @include fontSize(14px);
                            line-height: 24px;
                            margin-bottom: 0;
                            color: #707070;
                        }
                    }
                }
            }

            .react-multi-carousel-track {
                margin-bottom: 30px;
            }

            .react-multi-carousel-dot-list {

                .react-multi-carousel-dot {
                    button {
                        height: 10px;
                        width: 10px;
                        margin: 0px 3px;
                        border: none;
                        @include border-radius(10px);
                        background: #D9D9D9;
                    }

                    &.react-multi-carousel-dot--active {
                        button {
                            background: $theme-blue;
                            height: 15px;
                            width: 15px;
                            position: relative;
                            top: 3px
                        }
                    }
                }
            }
        }
    }

    .usp_section {
        background: #F3F2FC;
        border-radius: 20px;

        .uspSIlist {
            li {
                display: flex;
                gap: 16px;
                margin-bottom: 20px;
                align-items: flex-start;

                &:last-child {
                    margin-bottom: 0;
                }

                img {
                    min-width: 50px;
                }

                h5 {
                    @include fontSize(18px);
                    font-weight: 600;
                    color: #444;
                    margin-bottom: 8px;
                }
            }
        }

        .posp_usp_points {
            li {
                margin-bottom: 20px;
                padding-left: 30px;
                position: relative;
                color: #828282;
                @include fontSize(14px);
                line-height: 22px;
                color: #4f4f4f;

                &::before {
                    @include position(absolute, $top: 6px, $left: 0);
                    content: "";
                    border: 1px solid $theme-blue;
                    width: 9px;
                    height: 9px;
                    @include border-radius(50%);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.uspCrm {
            background: none;
        }

        &.uspPosp {
            background: none !important;
        }

        .uspCrmList {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            li {
                width: calc(50% - 12px);

                img {
                    min-width: 35px;
                    height: 35px;
                }

                p {
                    span {
                        font-weight: 500;
                    }
                }
            }
        }

        .uspErpList {
            li {
                position: relative;
                margin-bottom: 12px;
                padding: 20px 24px;
                background: $white;
                @include border-radius(10px);
                overflow: hidden;
                display: flex;
                align-items: center;
                gap: 16px;

                &:before {
                    @include position(absolute, $top: 0, $left: 0);
                    content: '';
                    width: 10px;
                    height: 100%;
                    background: $theme-blue;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .iconBox {
                    height: 60px;
                    min-width: 60px;
                    background: #F3F2FC;
                    border-radius: 50%;

                    img {
                        max-width: 40px;
                        height: 40px;
                        margin: auto;
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                .iconBox2 {
                    height: 50px;
                    min-width: 50px;

                    img {
                        max-width: 50px;
                        height: 50px;
                        margin: auto;
                    }
                }

                .para {
                    color: #4f4f4f;
                    @include fontSize(14px);

                    span {
                        font-weight: 600;
                        color: #444;
                        @include fontSize(16px);
                    }
                }
            }
        }

    }

    /////////////////
    .module_desc_section {
        .module_comtainer {
            padding: 0
        }

        .heading {
            @include fontSize(36px);
        }

        .si_module_desc_box {
            text-align: center;
            margin-bottom: 24px;
            padding: 16px;
            @include border-radius(20px);

            &:last-child {
                margin-bottom: 0;
            }

            &:nth-child(even) {
                background: $theme-blue;

                h5,
                p {
                    color: $white;
                }
            }

            img {
                height: 110px;
                margin: auto;
            }

            h5 {
                @include fontSize(16px);
                font-weight: 500;
                color: #444;
            }
        }

        .pos_module_pointsList {
            display: flex;
            flex-wrap: wrap;
            gap: 20px 12px;

            li {
                width: calc(50% - 6px);
                padding-left: 16px;
                position: relative;

                &:before {
                    content: "";
                    @include position(absolute, $top: 10px, $left: 0);
                    background: $theme-blue;
                    width: 4px;
                    height: 40px;
                    @include border-radius(6px);
                }

                img {
                    width: 60px;
                    height: 60px;
                }

                h6 {
                    @include fontSize(14px);
                    font-weight: 500;
                }

                p {
                    color: #828282;
                }
            }
        }

        .erp_module_box {
            box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10);
            @include border-radius(20px);
            padding: 250px 16px 20px;
            margin: 50px 12px 0px;
            text-align: center;
            position: relative;

            .icon_box {
                @include position(absolute, $top: -62px, $left: calc(50% - 143px));

                img {
                    margin: auto;
                    max-width: 286px;
                    height: 310px;
                }
            }

            h5 {
                @include fontSize(16px);
                font-weight: 600;
                margin-bottom: 6px;
                color: $theme-blue;
            }
        }

        .module_ia_list {
            display: flex;
            flex-wrap: wrap;
            gap: 20px 12px;

            li {
                width: calc(50% - 6px);
                text-align: center;

                img {
                    width: 80px;
                    height: 70px;
                    margin: auto
                }

                h6 {
                    @include fontSize(14px);
                    font-weight: 500;
                }

                p {
                    color: #828282;
                }
            }
        }

        .tabsBox {
            padding: 10px;
            background: #f3f2fc;
            @include border-radius(8px);

            .MuiTabs-flexContainer {
                justify-content: space-between;
                // gap: 20px;

                .MuiTab-root {
                    min-height: 44px;
                    text-transform: capitalize;
                    padding: 10px;
                    @include border-radius(10px);
                    @include fontSize(14px);
                    font-weight: 400;
                    font-family: "poppins";
                    color: #000000;
                    width: 100%;

                    &.Mui-selected {
                        background: $theme-blue;
                        color: $white;
                    }
                }
            }

            .MuiTabs-indicator {
                display: none;
            }
        }

        .tabPanel {
            // padding-left: 0px;
            // padding-right: 0px;
            // padding-bottom: 0px;
            padding: 0;
            position: relative;
            z-index: 0;

            .specification_box {
                padding: 20px;
                background: #EEEEFD;
                width: 100%;
                @include border-radius(8px);
                // margin-bottom: 20px;
                position: relative;
                overflow: hidden;
                z-index: 15;

                &:after {
                    @include position(absolute, $top: 50%, $left: 0);
                    transform: translateY(-50%);
                    content: '';
                    background: #F8F8FF;
                    height: 400%;
                    width: 92%;
                    z-index: -2;
                    border-radius: 0 70% 70% 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.active {
                    background: #7D73DE;
                    z-index: 8;
                    position: relative;
                    overflow: hidden;

                    &:before {
                        @include position(absolute, $top: 50%, $left: 0);
                        transform: translateY(-50%);
                        content: '';
                        background: $theme-blue;
                        height: 350%;
                        width: 90%;
                        z-index: -1;
                        border-radius: 10% 60% 60% 0%;
                    }

                    &:after {
                        display: none;
                    }

                    .top_part {
                        margin-bottom: 13px;

                        .iconBox {
                            background: $white !important;
                        }

                        h5 {
                            color: $white !important;
                        }
                    }

                    p {
                        color: #EBEBEB;
                        display: block;
                    }
                }

                .top_part {
                    display: flex;
                    align-items: center;
                    // margin-bottom: 13px;

                    .iconBox {
                        min-width: 72px;
                        height: 72px;
                        background: $theme-blue;
                        @include border-radius(8px);
                        margin-right: 13px;

                        img {
                            margin: auto;
                            position: relative;
                            top: 50%;
                            transform: translateY(-50%)
                        }
                    }


                    h5 {
                        @include fontSize(16px);
                        font-weight: 500;
                        color: #444;
                    }
                }

                p {
                    color: $black;
                    display: none;
                }
            }
        }
    }

    .usp_section_eb {
        .content {
            background: #F3F2FC;
            border-radius: 10px;
            padding: 20px;
            margin: 0 12px;

            @media(max-width:700px) {
                padding: 12px;
            }
        }

        .uspEbList {
            li {
                padding: 20px;
                border-radius: 20px;
                background: white;
                border-bottom: 5px solid $theme-blue;
                position: relative;
                overflow: hidden;
                height: 100%;

                &::before {
                    @include position(absolute, $top: -50px, $right: -50px);
                    content: "";
                    width: 92px;
                    height: 92px;
                    border: 1px solid$theme-blue;
                    border-radius: 50%;
                }

                &::after {
                    @include position(absolute, $top: -12px, $right: -78px);
                    content: "";
                    width: 92px;
                    height: 92px;
                    border: 1px solid$theme-blue;
                    border-radius: 50%;
                }

                h5 {
                    @include fontSize(18px);
                    font-weight: 600;
                    color: $black;
                    margin-bottom: 12px;
                }

                p {
                    color: #4F4F4F;
                }
            }

        }
    }

    // integartion kits and sdk
    .integration_services_features {
        .card {
            perspective: 1000px;
            position: relative;
            min-height: 234px;
            height: 100%;
            
            &:before {
                @include position(absolute, $left: 0, $bottom: 0);
                content: '';
                height: 160px;
                width: 100%;
                background: #F3F2FC;
                @include border-radius(12px);
                z-index: 0;
                transform: rotate(-6.409deg);
            }
            &:hover{
                &:before {
                    background: $theme-blue;
                }
                .card-inner{
                    transform: rotateY(180deg);
                    
                }
            }
            
            .card-inner {
                width: 100%;
                height: 100%;
                transform-style: preserve-3d;
                transition: transform 1s;
                background: $white;

                .card-front,
                .card-back {
                    padding: 24px;
                    text-align: center;
                    @include border-radius(12px);
                    width: 100%;
                    position: absolute;
                    backface-visibility: hidden;

                    h5 {
                        margin-bottom: 12px;
                        @include fontSize(18px);
                        font-weight: 600;
                    }

                    img {
                        margin: auto;
                    }
                }

                .card-back {
                    background: #F8F8FF;
                    @include position(absolute, $top: 0, $left: 0);
                    height: 100%;
                    transform: rotateY(180deg);
                }
            }
        }

    }
    .insurvent_advantage{
        .advantage_box_wrapper{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .advantage_box {
                width: calc((100% / 3) - 16px);
                position: relative;
                &:last-child{
                    &:before{
                        display: none;
                    }
                }
                &:before{
                    @include position(absolute, $top:15%,$right:-23%);
                    background: url('./../../../../public/images/insurvent_advantage_arrow.svg')no-repeat 0 0/100%;
                    content:'';
                    width: 138px;
                    height: 128px;
                    @media(max-width:1100px){
                        display: none;
                    }
                    
                }
                img{
                    margin: auto;
                }
                .content-box {
                    margin-top: 24px;
                    padding: 24px;
                    text-align: center;

                    h5 {
                        margin-bottom: 15px;
                        @include fontSize(18px);
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
