@import "./../../../Common/CommonScss/mixins.scss";

.productpage-wrapper {
    padding-top: 60px;

    h3 {
        @include fontSize(26px);
        font-weight: 600;
        line-height: 42px;
        color: #444;

        span {
            color: $theme-blue;
        }
    }

    p {
        @include fontSize(14px);
        line-height: 22px;
        color: #444;
    }

    .react-multi-carousel-track {
        margin-bottom: 40px;
    }

    .react-multi-carousel-dot-list {
        bottom: 4px;

        .react-multi-carousel-dot {
            button {
                border: none;
                background: #d9d9d9;
                height: 10px;
                width: 10px;
                margin: 0px 3px;
            }

            &.react-multi-carousel-dot--active {
                button {
                    background: $theme-blue;
                    height: 16px;
                    width: 16px;
                    position: relative;
                    top: 1.5px;
                }
            }
        }
    }

    .prouct-header {
        background: $theme-blue;

        h2 {
            @include fontSize(26px);
            line-height: 42px;
            color: $white;
            font-weight: normal;

            span {
                font-weight: 600;
            }
        }

        .product_header_img {
            img {
                max-width: 390px;
                margin: auto;
                width: 100%;
            }
        }
    }

    .product_info_section {
        .inner_box {
            padding: 20px 12px;
            @include border-radius(20px);
            background: #F3F2FC;

            img {
                max-width: 300px;
                margin: auto;
            }
        }
    }

    .module_desc_section {
        .si_module_desc_box {
            text-align: center;
            margin-bottom: 24px;
            padding: 16px;
            @include border-radius(20px);

            &:last-child {
                margin-bottom: 0;
            }

            &:nth-child(even) {
                background: $theme-blue;

                h5,
                p {
                    color: $white;
                }
            }

            img {
                height: 110px;
                margin: auto;
            }

            h5 {
                @include fontSize(16px);
                font-weight: 500;
                color: #444;
            }
        }

        .pos_module_pointsList {
            display: flex;
            flex-wrap: wrap;
            gap: 20px 12px;

            li {
                width: calc(50% - 6px);
                padding-left: 16px;
                position: relative;

                &:before {
                    content: "";
                    @include position(absolute, $top: 10px, $left: 0);
                    background: $theme-blue;
                    width: 4px;
                    height: 40px;
                    @include border-radius(6px);
                }

                img {
                    width: 60px;
                    height: 60px;
                }

                h6 {
                    @include fontSize(14px);
                    font-weight: 500;
                }

                p {
                    color: #828282;
                }
            }
        }

        .erp_module_box {
            box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10);
            @include border-radius(20px);
            padding: 250px 16px 20px;
            margin: 50px 12px 0px;
            text-align: center;
            position: relative;

            .icon_box {
                @include position(absolute, $top: -62px, $left: calc(50% - 143px));

                img {
                    margin: auto;
                    max-width: 286px;
                    height: 310px;
                }
            }

            h5 {
                @include fontSize(16px);
                font-weight: 600;
                margin-bottom: 6px;
                color: $theme-blue;
            }
        }

        .module_ia_list {
            display: flex;
            flex-wrap: wrap;
            gap: 20px 12px;

            li {
                width: calc(50% - 6px);
                text-align: center;

                img {
                    width: 80px;
                    height: 70px;
                    margin: auto
                }

                h6 {
                    @include fontSize(14px);
                    font-weight: 500;
                }

                p {
                    color: #828282;
                }
            }
        }

        .tabsBox {
            padding: 10px;
            background: #f3f2fc;
            @include border-radius(8px);

            .MuiTabs-flexContainer {
                justify-content: space-between;

                .MuiTab-root {
                    min-height: 44px;
                    text-transform: capitalize;
                    padding: 10px;
                    @include border-radius(10px);
                    @include fontSize(14px);
                    font-weight: 400;
                    font-family: "poppins";
                    color: #000000;

                    &.Mui-selected {
                        background: $theme-blue;
                        color: $white;
                    }
                }
            }

            .MuiTabs-indicator {
                display: none;
            }
        }

        .tabPanel {
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 0px;

            .specification_box {
                padding: 20px;
                background: #EEEEFD;
                width: 100%;
                @include border-radius(8px);
                margin-bottom: 20px;
                position: relative;
                overflow: hidden;
                z-index:15;

                &:after {
                    @include position(absolute, $top: 50%, $left: 0);
                    transform: translateY(-50%);
                    content: '';
                    background: #F8F8FF;
                    height: 200%;
                    width: 75%;
                    z-index: -2;
                    border-radius: 0 50% 50% 0;
                }
                

                &:last-child {
                    margin-bottom: 0;
                }

                &.active {
                    background: #7D73DE;
                    z-index: 8;
                    position: relative;
                    overflow: hidden;

                    &:before {
                        @include position(absolute, $top: 50%, $left: 0);
                        transform: translateY(-50%);
                        content: '';
                        background: $theme-blue;
                        height: 160%;
                        width: 75%;
                        z-index: -1;
                        border-radius: 0 70% 70% 0;
                    }
                    &:after{
                        display: none;
                    }

                    .top_part {
                        margin-bottom: 13px;

                        .iconBox {
                            background: $white !important;
                        }

                        h5 {
                            color: $white !important;
                        }
                    }

                    p {
                        color: #EBEBEB;
                        display: block;
                    }
                }

                .top_part {
                    display: flex;
                    align-items: center;
                    // margin-bottom: 13px;

                    .iconBox {
                        min-width: 72px;
                        height: 72px;
                        background: $theme-blue;
                        @include border-radius(8px);
                        margin-right: 13px;

                        img {
                            margin: auto;
                            position: relative;
                            top: 50%;
                            transform: translateY(-50%)
                        }
                    }


                    h5 {
                        @include fontSize(16px);
                        font-weight: 500;
                        color: #444;
                    }
                }

                p {
                    color: $black;
                    display: none;
                }
            }
        }
    }

    .crm_module_desc_section {
        padding: 20px 12px;
        background: $theme-blue;
        @include border-radius(20px);

        h3,
        p {
            color: $white;

            span {
                color: $white;
            }
        }

        .module_sec_img {
            max-width: 310px;
            margin: auto;
        }

        ul {
            li {
                background: $white;
                padding: 12px;
                @include border-radius(12px);
                display: flex;
                gap: 8px;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }

                img {
                    width: 50px;
                    height: 50px
                }

                h6 {
                    @include fontSize(14px);
                    color: #444;
                    margin-bottom: 8px;
                    font-weight: 500;
                }

                .points_list {
                    li {
                        @include fontSize(14px);
                        color: #828282;
                        margin-bottom: 8px;
                        line-height: 22px;
                        position: relative;
                        padding: 0 0 0 21px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &:before {
                            @include position(absolute, $top: 6px, $left: 0);
                            content: "";
                            background: url("./../../../../public/images/module_list_arrow.svg") no-repeat 0 0/16px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }

    .usp_section {
        background: #F3F2FC;

        .uspSIlist {
            li {
                display: flex;
                gap: 16px;
                margin-bottom: 20px;
                align-items: flex-start;

                &:last-child {
                    margin-bottom: 0;
                }

                img {
                    min-width: 50px;
                }

                h5 {
                    @include fontSize(16px);
                    font-weight: 600;
                    color: #444;
                    margin-bottom: 8px;
                }
            }
        }

        .posp_usp_points {
            li {
                margin-bottom: 10px;
                padding-left: 30px;
                position: relative;
                color: #828282;
                @include fontSize(14px);
                line-height: 22px;

                &::before {
                    @include position(absolute, $top: 6px, $left: 0);
                    content: "";
                    border: 1px solid $theme-blue;
                    width: 9px;
                    height: 9px;
                    @include border-radius(50%);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.uspCrm {
            background: none;
        }

        .uspCrmList {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            li {
                width: calc(50% - 6px);

                img {
                    min-width: 35px;
                    height: 35px;
                }

                p {
                    span {
                        font-weight: 500;
                    }
                }
            }
        }

        .uspErpList {
            li {
                position: relative;
                margin-bottom: 12px;
                padding: 8px 16px;
                background: $white;
                @include border-radius(10px);
                overflow: hidden;
                display: flex;
                align-items: center;
                gap: 12px;

                &:before {
                    @include position(absolute, $top: 0, $left: 0);
                    content: '';
                    width: 10px;
                    height: 100%;
                    background: $theme-blue;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .iconBox {
                    height: 60px;
                    min-width: 60px;
                    background: #F3F2FC;
                    border-radius: 50%;

                    img {
                        max-width: 40px;
                        height: 40px;
                        margin: auto;
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                .iconBox2 {
                    height: 50px;
                    min-width: 50px;

                    img {
                        max-width: 50px;
                        height: 50px;
                        margin: auto;
                    }
                }

                .para {
                    color: #828282;

                    span {
                        font-weight: 500;
                        color: #333;
                    }
                }
            }
        }

        .uspEbList {

            li {
                padding: 20px;
                border-radius: 20px;
                background: white;
                border-bottom: 5px solid $theme-blue;
                margin-bottom: 24px;
                position: relative;
                overflow: hidden;

                &::before {
                    @include position(absolute, $top: -39px, $right: -32px);
                    content: "";
                    width: 92px;
                    height: 92px;
                    border: 1px solid$theme-blue;
                    border-radius: 50%;
                }

                &::after {
                    @include position(absolute, $top: -4px, $right: -76px);
                    content: "";
                    width: 92px;
                    height: 92px;
                    border: 1px solid$theme-blue;
                    border-radius: 50%;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                h5 {
                    @include fontSize(16px);
                    font-weight: 600;
                    color: $black;
                    margin-bottom: 6px;
                }

                p {
                    color: rgba(0, 0, 0, 0.56);
                }
            }

        }
    }
}