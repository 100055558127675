@import "../../../Common/CommonScss/mixins.scss";

.getin_touch {
    .row {
        max-width: 1224px;
        width: 100%;
        margin: 0 auto;
    }

    .getin_touch_inner {
        background: #EFEEF8;
        @include border-radius(20px);
        width: 326px;
        height: 443px;

        img {
            position: relative;
            top: 122px;
            left: 70px;
        }
    }

    .formSection {
        background: rgba(100, 88, 216, 0.08);
        @include border-radius(20px);
        padding: 59px 40px;

        @media(max-width:992px) {
            margin-top: 24px !important;
        }
    }
}