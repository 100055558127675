@import "./../../../Common/CommonScss/mixins.scss";

.navbar_m {
  @include position(fixed, $top: 0px, $left: 0px);
  z-index: 9;
  width: 100%;
  background: $white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 10px 12px;

  .logo-section {
    .logo {
      height: 40px;
    }
  }

  .menuIcon {
    background: #f3f2fc;
    width: 32px;
    height: 32px;
    @include border-radius(50%);
    display: inline-block;
    position: relative;
    cursor: pointer;

    img {
      @include position(absolute, $top: 50%, $left: 50%);
      transform: translate(-50%, -50%);
    }
  }
}

.sliding-panel-container {
  position: fixed;
  top: 0;
  left: 0;

  &.active {
    z-index: 99;
    background: rgba(32, 56, 100, 0.15);

    .panel {
      // transition: transform 1s ;
      animation: inpanel 0.6s both;
    }

    @keyframes inpanel {
      0% {
        transform: translateX(1000px);
      }

      100% {
        transform: translateX(0px);
      }
    }
  }

  .glass {
    min-width: calc(100vw - 700px);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }

  .panel {
    height: 100%;
    width: 100%;
    max-width: 700px;
    background-color: white;
    animation: outpanel 0.3s both;
    padding: 12px;
  }

  @keyframes outpanel {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(1000px);
    }
  }

  .panel-content {
    .navbar_m {
      @include position(fixed, $top: 0px, $left: 0px);
      z-index: 9;
      width: 100%;
      background: $white;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
      padding: 10px 12px;

      .logo-section {
        .logo {
          height: 40px;
        }
      }
      .close-button {
        background: transparent;
        color: #000;
        @include border-radius(50%);
        $size: 40px;
        height: $size;
        width: $size;
        box-sizing: border-box;
        line-height: $size;
        z-index: 9;
        // display: inline-block;

        &:before,
        &:after {
          $width: 17px;
          $height: 1.5px;
          transform: rotate(-45deg);
          content: "";
          @include position(absolute, $right: 12px, $top: calc(50% - 2px));
          height: $height;
          width: $width;
          background-color: #000;
          transition: all 0.2s ease;
        }

        &:after {
          transform: rotate(-135deg);
        }

        &:hover {
          &:before {
            background-color: #de4b4b;

            transform: rotate(0deg);
          }

          &:after {
            background-color: #de4b4b;
            transform: rotate(-180deg);
          }
        }
      }
    }

    .menupopup {
      margin-top: 60px;
      .main_menu {
        li {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          min-height: 37px;
          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 36px;
            padding: 8px 0px;
            &.active {
              p {
                color: $theme-blue;
              }
              svg {
                color: $theme-blue;
                transform: rotate(-180deg);
                transition: transform 0.3s ease-in-out;
              }
            }
            p {
              @include fontSize(12px);
              line-height: 12px;
              color: #252243;
            }
            svg {
              @include fontSize(20px);
              color: #252243;
              transition: transform 0.3s ease-in-out;
            }
          }
          .MuiCollapse-root {
            .inner_menu {
              li {
                border-bottom: none;
                a {
                  padding: 8px 0px;
                  .inner_menu_box {
                    display: flex;
                    align-items: center;
                    img {
                      height: 20px;
                      min-width: 20px;
                      margin-right: 8px;
                    }
                    p {
                      @include fontSize(11px);
                      line-height: 11px;
                    }
                  }
                }
                .MuiCollapse-root {
                  background: rgba(224, 222, 247, 0.4);
                  .sub_inner_menu {
                    li {
                      a {
                        padding: 8px 0px 8px 8px;
                        p {
                          @include fontSize(10px);
                          line-height: 10px;
                        }
                        svg {
                            @include fontSize(20px);
                            color: #252243;
                            transform: rotate(-90deg);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
