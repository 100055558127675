@import "../../../Common/CommonScss/mixins.scss";

.cms_wrapper {
    padding-top: 92px;

    .row {
        max-width: 1224px;
        width: 100%;
        margin: 0 auto;
    }

    .about_img {
        position: relative;
        text-align: end;

        &:before {
            @include position(absolute, $bottom: 0, $left: 0);
            content: '';
            background: linear-gradient(0, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
            height: 100px;
            width: 100%;
        }

        @media(max-width: 1200px) {
            text-align: center;
        }

        img {
            height: 468px;
            display: inline-block;

            @media(max-width: 1200px) {
                height: 300px;
            }
        }
    }

    .ourgoals_box {
        @include border-radius(10px);
        background: #F5F7FA;
        // box-shadow: 0px 8px 16px 0px rgba(171, 190, 209, 0.40);
        padding: 16px;
        width: 384px;
        min-height: 408px;

        @media (max-width:830px) {
            width: 354px;
        }

        .ouricon_box {
            text-align: center;

            span {
                background: $theme-blue;
                @include border-radius(100%);
                display: inline-block;
                margin: auto;
                padding: 10px;

                img {
                    height: 90px;
                    width: 90px;
                }
            }
        }

        h6 {
            font-weight: 700;
            @include fontSize(20px);
            line-height: 35px;
            margin: 16px 0px;
            text-align: center;
            color: #252243;
        }

        p {
            font-weight: 400;
            @include fontSize(14px);
            line-height: 24px;
            margin-bottom: 0px;
            text-align: center;
            color: #252243;
        }
    }

    .founder_section {
        position: relative;

        &:before {
            content: '';
            background: url("../../../../public/images/founder_img.svg") no-repeat;
            height: 459px;
            width: 456px;
            @include position(absolute, $left: calc(50% - 230px), $top: -20px);
            z-index: -9;
        }

        .inner_section {
            background: $theme-blue;
            @include border-radius(10px);
            padding: 20px;

            .inner_content {
                display: flex;
                align-items: center;

                img {
                    height: 103px;
                    margin-right: 15px;
                }

                p {
                    @include fontSize(16px);
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 0;
                    color: $white;

                    span {
                        display: block;
                        padding-top: 6px;
                        @include fontSize(18px);
                        font-weight: 600;
                        line-height: 29px;
                    }
                }
            }
        }
    }

    .journey_section {
        height: 1000px;
        position: relative;

        &:before {
            content: '';
            background: url("../../../../public/images/our_journey.png") no-repeat center /100%;
            height: 100%;
            width: 100%;
            @include position(absolute, $left: 50%, $top: 80px);
            max-width: 1224px;
            max-height: 830px;
            transform: translateX(-50%);
        }

        .journey_content {
            h6 {
                font-weight: 800;
                @include fontSize(47px);
                line-height: 70px;
                color: #444444;
                margin-bottom: 0px;

                @media(max-width:1200px) {
                    @include fontSize(35px);
                    line-height: 55px;
                }

                @media(max-width: 992px) {
                    @include fontSize(25px);
                    line-height: 35px;
                }
            }

            p {
                font-weight: 600;
                @include fontSize(18px);
                line-height: 29px;
                margin-bottom: 0;
                color: #444444;

                @media(max-width:1200px) {
                    @include fontSize(14px);
                    line-height: 25px;
                }
            }

            &.lastoneYear {
                position: relative;
                left: 67%;
                top: 5%;
                width: 220px;
                display: block;

                @media(max-width: 1200px) {
                    top: 12%;
                }

                @media(max-width: 992px) {
                    left: 80%;
                    top: 28%;
                }

            }

            &.lasttwoYear {
                position: relative;
                left: 27%;
                top: 3%;
                width: 220px;
                display: block;

                @media(max-width: 1200px) {
                    top: 12%;
                }

                @media(max-width: 992px) {
                    left: 0;
                    top: 28%;
                }
            }

            &.lastthreeYear {
                position: relative;
                left: 78%;
                width: 220px;
                display: block;

                @media(max-width: 992px) {
                    left: 80%;
                    top: 24%;
                }
            }

            &.lastfourYear {
                position: relative;
                left: 7%;
                width: 240px;
                display: block;

                @media(max-width: 1200px) {
                    top: 15%;
                }

                @media(max-width: 992px) {
                    left: 0;
                    top: 27%;
                }
            }

            &.lastfiveYear {
                position: relative;
                left: 72%;
                width: 220px;
                display: block;

                @media(max-width: 992px) {
                    left: 80%;
                    top: 25%;
                }
            }
        }
    }

    .team_section {
        .team_images {
            position: relative;

            &:before {
                content: '';
                background: url("../../../../public/images/team_boy.svg") no-repeat;
                height: 100%;
                width: 316px;
                @include position(absolute, $right: -80px, $bottom: -10px);
            }

            .grid_box {
                @include border-radius(6px);

                img {
                    width: 100%;
                    height: 100%;
                    @include border-radius(16px);
                }
            }
        }
    }

    .contactus_header {
        .contact_img {
            text-align: end;

            @media(max-width: 1200px) {
                text-align: center;
            }

            img {
                height: 520px;
                display: inline-block;

                @media(max-width: 1200px) {
                    height: 450px;
                }
            }
        }

        .form_section {
            background: rgba(100, 88, 216, 0.08);
            @include border-radius(20px);
            padding: 40px;
            margin-top: 24px;
        }
    }

    .getin_touch {
        .address_section {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 100px;

            li {
                text-align: center;

                img {
                    padding-bottom: 12px;
                    display: inline-block;
                }

                p {
                    font-weight: 400;
                    @include fontSize(20px);
                    line-height: 36px;
                    color: #444444;
                    text-align: center;
                }

                a {
                    font-weight: 400;
                    @include fontSize(20px);
                    line-height: 36px;
                    color: #444444;
                    text-align: center;
                    display: block;
                }
            }

        }

        .maps {
            position: relative;

            iframe {
                margin-top: 60px;
                display: block;
                width: 100%;
            }
        }
    }
}