@import "./../../../Common/CommonScss/mixins.scss";

.cmspage-wrapper {
    padding-top: 60px;

    h3 {
        @include fontSize(24px);
        font-weight: 600;
        line-height: 40px;
        color: #444;

        span {
            color: $theme-blue;
        }
    }

    p {
        @include fontSize(14px);
        line-height: 24px;
        color: #707070;
    }

    .react-multi-carousel-track {
        margin-bottom: 40px;
    }

    .react-multi-carousel-dot-list {
        bottom: 4px;

        .react-multi-carousel-dot {
            button {
                border: none;
                background: #d9d9d9;
                height: 10px;
                width: 10px;
                margin: 0px 3px;
            }

            &.react-multi-carousel-dot--active {
                button {
                    background: $theme-blue;
                    height: 16px;
                    width: 16px;
                    position: relative;
                    top: 1.5px;
                }
            }
        }
    }

    .top-header {
        .about_img {
            position: relative;

            &:before {
                @include position(absolute, $bottom: 0, $left: 0);
                content: '';
                background: linear-gradient(0, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
                height: 100px;
                width: 100%;
            }

            img {
                margin: auto;
            }
        }
    }

    .our_goals_sec {
        .id_box {
            text-align: center;
            box-shadow: 0px 8px 16px 0px rgba(171, 190, 209, 0.40);
            @include border-radius(10px);
            padding: 12px;
            background: #F5F7FA;
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }

            .icon_box {
                span {
                    @include border-radius(50%);
                    background: $theme-blue;
                    padding: 10px;
                    display: inline-block;
                    margin: auto;

                    img {
                        width: 90px;
                        height: 90px;
                    }
                }
            }

            h5 {
                @include fontSize(16px);
                font-weight: 700;
                margin-bottom: 12px;
                margin-top: 10px;
            }

            p {
                color: rgba(0, 0, 0, 0.56);
            }
        }
    }

    .our_founders_sec {
        .f_desc_box {
            background: $theme-blue;
            @include border-radius(20px);
            padding: 20px;
            text-align: center;

            img {
                margin: auto;
                @include border-radius(50%);
                width: 100px;
                height: 100px;
            }

            h5 {
                @include fontSize(16px);
                font-weight: 700;
                margin-bottom: 4px;
                margin-top: 10px;
                color: $white;
            }

            p {
                color: $white;
            }
        }
    }

    .our_journey_sec {
        .journey_detail_outer {
            position: relative;

            &::after {
                @include position(absolute, $bottom: 0, $left: 0);
                content: '';
                background: url('./../../../../public/images/journey_bg_m.svg') no-repeat center/160%;
                width: 100%;
                height: 100%;
                z-index: -1;
            }

            .journey_detail_list {
                display: flex;
                align-items: center;
                gap: 12px;
                justify-content: center;
                flex-wrap: wrap;

                li {
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    padding: 10px;
                    @include border-radius(6px);
                    width: calc(50% - 6px);
                    display: flex;
                    gap: 10px;
                    z-index: 9;
                    background: $white;

                    img {
                        width: 20px;
                    }

                    .d_box {
                        h6 {
                            @include fontSize(14px);
                            color: #444;
                            font-weight: 700;
                        }

                        p {
                            @include fontSize(12px);
                            color: #444;
                            line-height: 16px;
                        }
                    }
                }
            }
        }

    }

    .our_team_sec {
        .team_pics {
            .gridBox {
                @include border-radius(16px);

                img {
                    width: 100%;
                    height: 100%;
                    @include border-radius(16px);
                }
            }
        }
    }

    .contact_sec {
        background: linear-gradient(180deg, #E8D8E6 0%, #EEE5EE 100%);
        @include border-radius(24px);
        padding: 20px;

        img {
            margin: auto;
        }
    }

    .contact_details_sec {
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
        @include border-radius(10px);
        padding: 20px;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;

            li {
                width: calc(50% - 5px);
                text-align: center;

                img {
                    margin: auto;
                    width: 60px;
                    height: 60px;
                }

                p,
                a {
                    color: #444;
                }

                a {
                    display: inline-block;
                }
            }
        }
    }

    .company_location_mark {
        padding-top: 35px;
        position: relative;

        &:before {
            @include position(absolute, $top: 0, $left: 15px);
            content: '';
            background: url('./../../../../public/images/aeroplane_icon.svg')no-repeat 0 0 /100px;
            width: 100px;
            height: 60px;
        }
    }

    .partners-section {
        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            li {
                width: calc((100% / 3) - 8px);
                padding: 12px 8px;
                background: $white;
                @include border-radius(12px);
                box-shadow: 26px 26px 89px 16px rgba(0, 0, 0, 0.10);

                img {
                    height: 45px;
                    margin: auto;
                }
            }
        }
    }

    .achievements_counter {
        background: $theme-blue;

        ul {
            display: flex;
            row-gap: 24px;
            column-gap: 6px;
            flex-wrap: wrap;

            li {
                width: calc(50% - 3px);
                @include fontSize(14px);
                text-align: center;
                color: $white;

                &:nth-child(odd) {
                    border-right: 1px solid #a29be8;
                }

                span {
                    @include fontSize(20px);
                    font-weight: 700;
                    line-height: 30px;
                    margin-bottom: 6px;
                    display: inline-block;
                }
            }
        }
    }
}