@import "./../../../Common/CommonScss/mixins.scss";

.homepage-wrapper {
  padding-top: 60px;

  h3 {
    @include fontSize(24px);
    font-weight: 600;
    line-height: 40px;

    span {
      color: $theme-blue;
    }
  }

  p {
    @include fontSize(14px);
    line-height: 24px;
    color: #707070;
  }

  .react-multi-carousel-track {
    margin-bottom: 40px;
  }

  .react-multi-carousel-dot-list {
    bottom: 4px;

    .react-multi-carousel-dot {
      button {
        border: none;
        background: #d9d9d9;
        height: 10px;
        width: 10px;
        margin: 0px 3px;
      }

      &.react-multi-carousel-dot--active {
        button {
          background: $theme-blue;
          height: 16px;
          width: 16px;
          position: relative;
          top: 1.5px;
        }
      }
    }
  }

  .top-header {
    .carousel-box {
      h2 {
        @include fontSize(26px);
        font-weight: 600;
        line-height: 42px;

        span {
          color: $theme-blue;
        }
      }

      img {
        max-height: 260px;
        height: 100%;
        margin: auto;
      }
    }
  }

  .big_brand_business {
    .partner_box {
      padding: 20px 8px;
      background: #fff;
      border-radius: 20px;
      margin: 8px 4px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      box-shadow: 26px 26px 89px 0px rgba(0, 0, 0, 0.1);

      img {
        height: 60px;
        margin: auto;
      }
    }

    .react-multi-carousel-list {
      padding: 20px 0px;
    }

    .react-multi-carousel-dot-list {
      display: none;
    }

    .react-multi-carousel-track {
      margin-bottom: 0;
    }
  }

  .li_brokers_dependency {
    .our_feature_box {
      border-radius: 20px;
      border: 1px solid #c1bcef;
      background: #ebeafe;
      padding: 20px 12px;
      text-align: center;
      margin-bottom: 20px;
      position: relative;
      overflow: hidden;

      &:before {
        content: "";
        @include position(absolute, $top: 0, $right: 0);
        background: url("./../../../../public/images/Ellipse.svg") no-repeat 0 0 /100%;
        width: 167px;
        height: 127px;
      }

      &:after {
        content: "";
        @include position(absolute, $bottom: 0, $left: 0);
        background: url("./../../../../public/images/Ellipse.svg") no-repeat 0 0 /100%;
        width: 167px;
        height: 127px;
        transform: rotate(180deg);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.blugbg {
        background: $theme-blue;

        h5,
        p {
          color: $white;
        }
      }

      img {
        width: 130px;
        height: 105px;
        margin: auto;
      }

      h5 {
        @include fontSize(16px);
        font-weight: 700;
        margin-bottom: 6px;
        margin-top: 10px;
      }

      p {
        color: #333333;
      }
    }
  }

  .best_in_market {
    .tabsBox {
      padding: 10px 12px;
      background: #f3f2fc;

      .MuiTabs-flexContainer {
        gap: 20px;
        overflow-x: auto;

        .MuiTab-root {
          min-height: 44px;
          text-transform: capitalize;
          padding: 10px;
          @include border-radius(10px);
          @include fontSize(14px);
          font-weight: 400;
          font-family: "poppins";
          color: #000000;

          &.Mui-selected {
            background: $theme-blue;
            color: $white;
          }
        }
      }

      .MuiTabs-indicator {
        display: none;
      }
    }

    .tabPanel {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;

      .bim_box {
        padding: 10px;
        background: #7D73DE;
        width: 100%;
        @include border-radius(10px);
        margin-bottom: 20px;
        position: relative;
        overflow: hidden;
        z-index: -2;

        &:before {
          @include position(absolute, $top: 50%, $left: 0);
          transform: translateY(-50%);
          content: '';
          background: $theme-blue !important;
          height: 160%;
          width: 70%;
          z-index: -1;
          border-radius: 0 70% 70% 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .top_part {
          display: flex;
          align-items: center;
          margin-bottom: 13px;

          img {
            min-width: 60px;
            height: 60px;
            margin-right: 13px;
          }

          h5 {
            @include fontSize(16px);
            font-weight: 700;
            color: $white;
          }
        }

        p {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .our_insurance_catalogue {
    background: #f3f2fc;

    ul {
      display: flex;
      // justify-content: center;
      flex-wrap: wrap;
      gap: 64px 12px;
      justify-content: center;

      li {
        width: calc(50% - 6px);
        background: white;
        padding: 80px 10px 30px;
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1);
        @include border-radius(20px);
        text-align: center;
        position: relative;

        span {
          display: inline-block;
          height: 90px;
          width: 90px;
          border-radius: 10px;
          @include position(absolute, $top: -40px, $left: calc(50% - 45px));

          img {
            top: 50%;
            position: relative;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &.health {
            background: #ffb197;
          }

          &.term {
            background: #f2d3ff;
          }

          &.car {
            background: #aabcff;
          }

          &.bike {
            background: #fcecb6;
          }

          &.travel {
            background: #e0c7fb;
          }

          &.investment {
            background: #edd5b4;
          }

          &.shopkeeper {
            background: #c2e7f3;
          }

          &.home {
            background: #b5ceff;
          }

          &.commercial {
            background: #d6d2ff;
          }
        }

        p {
          @include fontSize(16px);
          font-weight: 500;
          color: $black;
        }
      }
    }
  }

  .upcoming_products {
    .Tabs {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0px 12px;
      margin-bottom: 30px;

      .nav-link {
        color: #7c7c7c;
        @include fontSize(16px);
        line-height: 16px;
        font-weight: 500;
        position: relative;
        text-transform: capitalize;
        width: calc(100%/3 - 8px);
        padding: 0;
        display: block;
        margin-top: 70px;

        &.active {
          color: $black;

          .product_box {
            background: $theme-blue;
            img{
              filter: grayscale(0%);
            }
          }
        }

        .product_box {
          border-radius: 0px 0px 80px 80px;
          background: #E0E0E0;
          height: 80px;
          margin-bottom: 20px;
          position: relative;

          img {
            @include position(absolute, $top: -50%, $left: 50%);
            transform: translateX(-50%);
            max-width: 90px;
            filter: grayscale(50%);
          }
        }
      }
    }

    .TabsWrapper {
      position: relative;

      &:before {
        @include position(absolute, $bottom: 0, $left: 0);
        content: '';
        background: url('./../../../../public/images/upcoming_bg.svg')no-repeat center/100%;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      .productdetailBox {
        text-align: center;

        img {
          margin: auto;
          max-width: 190px;
          height: 167px
        }

        h5 {
          @include fontSize(16px);
          font-weight: 700;
          color: $black;
          margin-top: 24px;
          margin-bottom: 12px;
        }
      }
    }
  }

  .insurvent_differnce_sec {
    .id_box {
      margin: 0px 12px;
      text-align: center;

      .icon_box {
        span {
          @include border-radius(20px);
          background: #f3f2fc;
          padding: 20px;
          display: inline-block;
          margin: auto;

          img {
            width: 196px;
          }
        }
      }

      h5 {
        @include fontSize(16px);
        font-weight: 700;
        margin-bottom: 6px;
        margin-top: 10px;
      }

      p {
        color: rgba(0, 0, 0, 0.56);
      }
    }
  }

  .services_overview {
    .so_box {
      @include border-radius(30px);
      background: $theme-blue;
      padding: 140px 24px 24px;
      text-align: center;
      position: relative;
      margin: 80px 12px 0px;

      img {
        @include position(absolute, $top: -80px, $left: 50%);
        transform: translateX(-50%);
        height: 205px;
        max-width: 245px;
      }

      h4 {
        @include fontSize(20px);
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 6px;
        color: $white;
      }

      p {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .achievements_counter {
    background: #f3f2fc;

    ul {
      display: flex;
      row-gap: 24px;
      column-gap: 6px;
      flex-wrap: wrap;

      li {
        width: calc(50% - 3px);
        @include fontSize(14px);
        text-align: center;

        &:nth-child(odd) {
          border-right: 1px solid #a29be8;
        }

        span {
          @include fontSize(20px);
          font-weight: 700;
          line-height: 30px;
          margin-bottom: 6px;
          display: inline-block;
        }
      }
    }
  }

  .clients_feedback {
    .cf_box {
      @include border-radius(20px);
      border: 1px solid #6458d8;
      padding: 20px 12px;
      text-align: center;
      margin: 0px 12px;
      height: 100%;

      .client_profile_pic {
        position: relative;

        .profile {
          width: 100px;
          height: 100px;
          @include border-radius(50%);
          margin: auto;
        }

        span {
          @include position(absolute, $bottom: 0, $left: calc(60% - 20px));
        }
      }

      h5 {
        @include fontSize(16px);
        font-weight: 700;
        color: rgba(0, 0, 0, 0.56);
      }

      h6 {
        @include fontSize(14px);
        font-weight: 400;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.56);
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      p {
        font-weight: 400 !important;
        margin-bottom: 10px;
      }

      .MuiStack-root {
        span {
          margin: auto;
        }
      }
    }

    .react-multi-carousel-dot-list {
      display: none;
    }

    .react-multi-carousel-track {
      margin-bottom: 0;
    }
  }

  .company_quote {
    .cq_box {
      padding: 20px 12px;
      background: $theme-blue;
      @include border-radius(20px);

      p {
        color: $white;
        font-weight: 400 !important;
        margin-bottom: 10px;
      }

      svg {
        color: $white;
        animation: moveLeftRight 1s infinite ease-in-out;
      }

      @keyframes moveLeftRight {

        0%,
        100% {
          transform: translateX(0);
        }

        50% {
          transform: translateX(10px);
          /* adjust this value based on how much you want the arrow to move */
        }
      }
    }
  }

  .getintouch_sec {
    .img-sec {
      border-radius: 20px;
      background: #f3f2fc;
      padding: 10px;

      img {
        width: 100%;
        max-width: 350px;
        max-height: 344px;
        margin: auto;
      }
    }

    .field-sec {
      border-radius: 20px;
      background: #f3f2fc;
      padding: 20px;
    }
  }
}