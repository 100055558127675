@import "./../../../Common/CommonScss/mixins.scss";

.footer_m{
    background: $theme-blue;
    padding: 20px 12px;
    .logo {
        height: 50px;
    }
    .footer_boy{
        width: 160px;
        height: 147px;
    }
    h4{
        @include fontSize(16px);
        font-weight: 700;
        color: $white;
        margin-bottom: 20px;
    }
    ul{
        li{
            margin-bottom: 16px;
            &:last-child{
                margin-bottom: 0;
            }
            a{
                @include fontSize(14px);
                line-height: 24px;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.60);
            }
        }
    }
    .social-icons{
        margin-top: 24px;
        display: flex;
        li{
            margin-right: 16px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .footer_db{
        border-top: 1px solid rgba(255, 255, 255, 0.60);
        padding-top: 20px;
        .mb_num{
            color: $white;
        }
        p{
            color: $white;
            font-weight: 400;
        }
    }
}